import React from "react";

const FailedHumanitiesSVG = ({
    style = {},
    fill = "#000",
    width = "100%",
    height = "100%",
    className = "",
    viewBox = "0 0 1800.000000 1800.000000",
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g transform="translate(0.000000,1800.000000) scale(0.100000,-0.100000)"
            fill={fill} stroke={fill} stroke-width="200">
            <path d="M3380 17104 c-310 -45 -509 -140 -705 -340 -486 -494 -659 -1541
-484 -2928 11 -87 18 -160 16 -162 -1 -2 -50 -8 -108 -13 -187 -18 -328 -55
-464 -124 -250 -125 -446 -359 -541 -644 -124 -375 -98 -941 72 -1593 318
-1216 931 -2377 1899 -3595 627 -788 1591 -1824 2435 -2615 96 -90 209 -196
250 -235 59 -56 104 -115 213 -281 75 -116 137 -220 137 -231 0 -11 -14 -87
-30 -169 -71 -350 -141 -837 -176 -1214 -21 -236 -30 -801 -15 -1005 78 -1097
455 -1731 1112 -1874 164 -36 403 -32 608 10 418 85 943 350 1447 730 50 38
95 69 99 69 4 0 49 -31 99 -69 329 -248 730 -484 1021 -599 584 -232 1059
-222 1432 30 207 139 379 365 496 653 119 291 187 618 218 1050 15 204 6 769
-15 1005 -35 377 -105 864 -176 1214 -16 82 -30 158 -30 169 0 11 52 100 115
197 115 175 118 179 278 325 780 711 1714 1683 2384 2481 1002 1195 1663 2309
2038 3439 288 864 371 1543 250 2022 -65 256 -211 483 -402 627 -177 134 -374
204 -635 227 -73 6 -134 13 -136 14 -2 1 6 74 17 161 137 1080 60 1981 -214
2537 -330 668 -922 888 -1700 632 -452 -148 -956 -448 -1475 -879 -259 -215
-410 -354 -716 -660 -969 -970 -1844 -2125 -2833 -3738 l-17 -28 -19 33 c-167
288 -618 983 -900 1387 -659 944 -1263 1678 -1929 2346 -648 648 -1151 1040
-1710 1334 -390 205 -767 313 -1079 309 -56 -1 -113 -3 -127 -5z m250 -164
c819 -73 1914 -840 3093 -2167 740 -834 1494 -1870 2200 -3025 l127 -207 -98
-168 c-191 -326 -519 -918 -623 -1125 l-30 -59 -130 123 c-1243 1177 -2753
2206 -4194 2858 -575 260 -1080 424 -1465 477 l-129 17 -5 30 c-11 61 -46 365
-61 536 -22 244 -31 754 -16 965 48 689 205 1152 490 1448 190 198 374 277
711 306 8 0 67 -4 130 -9z m11241 0 c262 -25 456 -116 630 -297 285 -296 442
-759 490 -1448 15 -213 6 -721 -16 -970 -18 -200 -61 -554 -68 -562 -3 -2 -49
-10 -103 -18 -749 -105 -1921 -625 -3154 -1398 -851 -534 -1835 -1298 -2491
-1934 -81 -79 -149 -143 -153 -143 -3 0 -21 31 -40 68 -107 212 -433 802 -628
1135 l-98 168 127 207 c1100 1800 2292 3276 3393 4202 140 118 403 318 565
430 466 322 948 526 1322 559 48 5 95 9 103 10 8 0 63 -4 121 -9z m-12626
-3492 c2 -13 14 -79 25 -148 77 -462 219 -1074 376 -1621 110 -380 103 -336
61 -405 -88 -143 -122 -284 -121 -504 2 -432 188 -1033 553 -1785 330 -679
801 -1391 1513 -2287 133 -167 163 -213 287 -435 122 -219 279 -490 425 -738
l47 -80 -108 105 c-316 306 -781 785 -1098 1130 -1238 1349 -1995 2443 -2475
3574 -259 611 -439 1239 -502 1756 -19 157 -16 511 6 635 81 457 360 744 790
810 45 7 111 13 148 14 62 1 68 -1 73 -21z m14112 -2 c360 -77 616 -325 708
-681 60 -236 61 -587 4 -945 -62 -392 -225 -953 -404 -1395 -477 -1177 -1204
-2265 -2394 -3580 -527 -581 -1487 -1556 -1414 -1435 100 165 229 386 350 600
214 377 212 374 434 650 720 898 1217 1648 1560 2355 226 467 381 878 469
1248 48 204 61 295 67 467 8 249 -27 401 -129 558 -37 55 -49 82 -45 101 2 14
39 145 81 290 157 546 299 1159 376 1621 11 69 23 136 26 149 l5 24 117 -6
c64 -3 149 -13 189 -21z m-13790 -6 c797 -136 2109 -755 3328 -1570 790 -528
1574 -1153 2165 -1727 l142 -138 -124 -248 -124 -249 -49 39 c-240 189 -631
470 -948 682 -753 502 -1500 891 -2200 1146 -666 243 -1208 319 -1581 224 -85
-22 -205 -73 -257 -110 -17 -12 -34 -18 -39 -13 -8 10 -98 325 -174 609 -91
340 -189 780 -250 1120 -20 110 -39 212 -42 228 -7 34 -5 34 153 7z m13309 -7
c-3 -16 -22 -118 -42 -228 -85 -475 -217 -1027 -370 -1555 -25 -85 -47 -156
-49 -158 -2 -3 -23 7 -47 20 -333 193 -900 165 -1638 -81 -942 -315 -1963
-878 -3036 -1676 -105 -79 -227 -171 -270 -205 l-78 -62 -124 248 -125 249
124 118 c1194 1139 2583 2111 3959 2771 667 321 1187 503 1650 579 51 9 52 8
46 -20z m-11981 -2022 c894 -159 2078 -724 3258 -1553 312 -219 698 -509 706
-530 2 -7 -189 -434 -206 -457 -1 -2 -45 22 -98 53 -277 166 -579 289 -810
332 -126 23 -336 16 -420 -15 -171 -62 -266 -182 -296 -373 -26 -167 35 -471
151 -763 101 -253 324 -657 567 -1029 l104 -159 -105 -301 c-154 -444 -308
-938 -418 -1344 -18 -68 -38 -122 -43 -120 -14 4 -265 265 -465 483 -249 271
-636 718 -889 1027 -79 97 -132 195 -434 798 -530 1058 -915 1954 -1283 2987
-110 310 -264 778 -264 803 0 56 197 149 390 184 14 2 117 3 230 1 154 -2 235
-8 325 -24z m11127 15 c139 -25 291 -90 321 -137 22 -36 -332 -1056 -603
-1734 -172 -429 -389 -932 -616 -1420 -123 -266 -564 -1144 -681 -1357 -91
-165 -111 -193 -273 -385 -214 -253 -427 -497 -615 -703 -204 -223 -535 -570
-544 -570 -4 0 -31 86 -60 192 -112 411 -271 916 -422 1347 l-79 223 104 157
c220 333 436 715 545 963 201 455 254 838 144 1040 -55 102 -170 183 -300 214
-94 22 -324 14 -443 -16 -226 -56 -527 -188 -770 -339 -45 -28 -83 -51 -85
-51 -2 0 -8 12 -13 28 -6 15 -53 118 -105 229 l-95 201 122 94 c1147 890 2286
1534 3284 1858 266 87 516 144 757 173 80 10 357 5 427 -7z m-5683 -386 c209
-366 541 -978 541 -996 0 -3 -54 -63 -119 -132 -227 -242 -387 -436 -526 -636
-37 -53 -70 -96 -73 -95 -4 0 -44 53 -90 119 -158 225 -318 414 -609 721 l-44
46 114 214 c194 365 449 824 575 1033 33 55 36 58 49 40 8 -10 90 -152 182
-314z m6170 6 c62 -200 47 -444 -49 -811 -175 -674 -559 -1469 -1095 -2270
-153 -229 -405 -579 -411 -572 -2 2 61 136 140 298 491 1003 924 2054 1263
3066 65 194 121 353 125 353 4 0 16 -29 27 -64z m-12570 -307 c328 -977 746
-1993 1227 -2989 63 -129 104 -224 93 -210 -74 87 -389 553 -541 800 -460 748
-794 1533 -910 2140 -25 131 -35 363 -19 451 13 77 31 142 37 137 2 -3 53
-150 113 -329z m5529 -1011 c195 -212 339 -387 528 -641 90 -120 167 -218 171
-219 4 -1 28 28 53 64 207 301 436 585 662 823 l95 100 117 -232 c64 -128 116
-235 116 -237 0 -2 -75 -67 -167 -143 -241 -201 -404 -343 -601 -527 -96 -88
-198 -183 -228 -209 l-54 -49 -152 144 c-262 245 -499 455 -775 682 -79 65
-143 119 -143 122 0 5 234 474 236 474 1 0 65 -69 142 -152z m-178 -747 c191
-162 453 -397 603 -542 l128 -124 -140 -139 -140 -139 -86 74 c-259 221 -513
420 -737 578 -65 45 -118 87 -118 93 0 11 42 105 144 321 l56 119 77 -64 c43
-35 139 -114 213 -177z m2115 -4 c53 -114 95 -212 93 -217 -2 -5 -70 -56 -151
-114 -243 -173 -684 -527 -736 -589 -7 -10 -19 -17 -26 -17 -10 0 -265 261
-273 279 -5 13 398 384 653 602 268 228 330 278 337 271 4 -4 50 -101 103
-215z m-3704 87 c200 -33 467 -142 746 -304 l123 -71 -14 -32 c-203 -462 -474
-1122 -591 -1436 -33 -86 -19 -95 -129 79 -253 399 -440 764 -531 1035 -75
224 -103 447 -71 558 46 157 204 215 467 171z m5190 2 c80 -18 144 -58 173
-109 104 -178 3 -613 -256 -1107 -128 -242 -421 -730 -439 -730 -4 0 -28 57
-54 128 -93 249 -308 777 -441 1084 -74 172 -136 319 -137 327 -4 31 363 232
573 314 237 93 442 126 581 93z m-3998 -600 c266 -193 707 -552 707 -576 0 -5
-66 -82 -147 -171 -82 -88 -151 -167 -155 -174 -6 -10 -22 -9 -80 7 -52 15
-106 21 -188 22 -101 1 -121 -2 -167 -23 -61 -27 -116 -84 -146 -150 -34 -75
-36 -242 -3 -400 13 -69 22 -130 18 -136 -32 -52 -306 -404 -312 -400 -7 5
-249 335 -329 450 l-42 61 81 214 c146 386 419 1047 549 1333 l32 68 30 -18
c17 -10 85 -58 152 -107z m2722 47 c121 -261 457 -1082 585 -1430 l53 -142
-74 -103 c-134 -188 -271 -373 -277 -375 -4 -1 -76 91 -161 203 l-154 206 24
116 c26 132 32 310 11 378 -29 97 -105 170 -207 199 -73 22 -206 16 -307 -13
l-68 -19 -113 126 c-63 69 -135 150 -162 179 l-48 53 53 47 c93 82 313 264
421 348 121 95 381 283 391 284 3 0 19 -26 33 -57z m-1185 -609 c0 -5 -61 -70
-135 -144 l-135 -135 -135 135 c-74 74 -134 138 -133 142 2 4 64 69 138 143
l135 136 132 -134 c73 -74 133 -139 133 -143z m-536 -284 c84 -85 126 -135
122 -145 -3 -8 -45 -59 -92 -113 l-88 -99 -74 44 c-40 24 -118 66 -173 93 -54
28 -99 55 -99 60 0 14 252 290 265 290 6 0 69 -59 139 -130z m813 -10 c70 -77
129 -144 131 -148 1 -4 -33 -25 -75 -46 -43 -21 -120 -63 -172 -93 -52 -29
-99 -52 -106 -50 -6 3 -51 51 -100 107 l-88 101 134 135 c73 74 137 134 141
134 4 0 65 -63 135 -140z m-1585 -239 c13 -5 -7 -35 -98 -146 -63 -77 -118
-140 -122 -140 -12 0 -25 140 -18 189 9 60 41 97 92 105 40 7 116 3 146 -8z
m2262 -4 c41 -15 56 -60 55 -159 -2 -145 -3 -146 -89 -42 -40 49 -94 113 -118
142 -24 29 -41 56 -38 60 10 9 96 19 131 14 17 -2 43 -9 59 -15z m-1139 -124
l77 -88 -29 -23 c-15 -12 -51 -39 -80 -58 l-51 -36 -81 57 -80 58 31 36 c17
20 52 60 77 88 25 29 49 53 53 53 3 0 41 -39 83 -87z m-754 -11 c175 -89 223
-120 211 -135 -5 -7 -42 -55 -82 -107 -39 -52 -84 -101 -98 -109 -67 -34 -119
-106 -146 -203 -15 -54 -19 -58 -56 -68 -49 -13 -99 -54 -116 -97 -8 -18 -14
-58 -14 -90 0 -33 -7 -66 -14 -76 -13 -17 -16 -13 -41 40 -87 187 -180 440
-168 456 102 133 372 457 382 457 4 0 68 -31 142 -68z m1601 -62 c185 -222
268 -328 268 -342 0 -21 -53 -162 -128 -342 -65 -158 -66 -159 -84 -136 -13
16 -18 39 -18 82 0 95 -40 156 -116 174 -44 10 -51 19 -73 93 -24 79 -73 149
-126 176 -24 13 -66 58 -116 123 -43 57 -79 106 -79 110 0 15 344 196 361 190
3 -2 53 -59 111 -128z m-1149 -215 c31 -22 57 -43 57 -46 0 -3 -23 -25 -50
-48 l-51 -43 -54 29 c-30 15 -55 31 -55 35 0 11 84 118 90 115 3 -1 31 -20 63
-42z m578 -47 l19 -26 -54 -31 c-29 -17 -56 -31 -60 -31 -10 0 -96 81 -96 91
0 4 26 26 58 48 l57 40 28 -32 c16 -18 37 -44 48 -59z m-304 -169 c26 -23 49
-45 51 -48 1 -4 -23 -31 -53 -61 l-55 -54 -56 54 -55 55 53 47 c29 26 56 48
60 48 4 0 28 -19 55 -41z m-2197 -61 c24 -35 95 -132 158 -217 62 -84 116
-159 118 -165 3 -7 -51 -95 -119 -196 -252 -376 -413 -645 -577 -962 l-106
-208 -34 35 -33 34 58 213 c32 117 91 326 132 463 87 294 342 1065 352 1065 4
0 27 -28 51 -62z m1697 14 l33 -18 -74 -73 c-74 -73 -98 -83 -112 -47 -7 19
86 156 106 156 8 -1 30 -9 47 -18z m997 -46 c49 -68 57 -100 29 -110 -12 -5
-38 14 -92 68 l-75 75 34 15 c19 8 40 15 47 16 6 0 32 -29 57 -64z m1755 -328
c146 -441 219 -679 327 -1073 73 -268 74 -270 54 -289 -11 -11 -21 -18 -23
-15 -2 2 -44 83 -94 180 -171 332 -339 613 -580 971 l-132 198 146 197 c80
109 148 203 150 210 3 7 9 13 13 13 5 0 67 -177 139 -392z m-3606 210 c25 -62
74 -179 110 -259 l65 -147 -67 -118 c-36 -64 -70 -119 -76 -120 -10 -3 -96 97
-257 299 -57 70 -85 113 -80 122 18 31 248 334 253 335 4 0 27 -51 52 -112z
m2781 -57 c69 -92 126 -171 126 -176 0 -16 -327 -415 -340 -415 -8 0 -140 232
-140 246 0 5 29 72 64 149 34 77 83 191 107 252 24 62 47 113 50 113 4 0 64
-76 133 -169z m-1693 42 c19 -21 45 -50 58 -65 l23 -27 -58 -80 c-32 -45 -61
-81 -64 -81 -3 0 -39 34 -80 75 l-74 74 70 71 c39 38 75 70 80 70 6 0 26 -17
45 -37z m544 -33 l71 -71 -75 -77 -76 -78 -62 85 -63 86 62 62 c34 35 65 63
67 63 3 0 37 -32 76 -70z m-785 -305 c44 -44 80 -86 80 -93 0 -11 -50 -110
-66 -131 -2 -2 -16 7 -33 18 -22 16 -36 39 -50 84 -42 139 -50 215 -21 205 6
-2 47 -39 90 -83z m514 8 c31 -43 56 -83 56 -90 0 -7 -26 -53 -59 -102 l-59
-90 -62 94 c-37 57 -59 99 -54 107 20 36 110 158 116 158 4 0 32 -35 62 -77z
m495 63 c12 -15 1 -73 -36 -191 -14 -45 -56 -105 -74 -105 -7 0 -79 117 -79
129 0 11 160 181 170 181 4 0 13 -6 19 -14z m-2162 -273 c67 -82 148 -179 180
-217 l60 -69 -84 -176 c-197 -409 -300 -682 -387 -1031 -24 -96 -47 -196 -51
-222 -4 -27 -12 -48 -17 -48 -22 0 -608 557 -608 577 0 41 291 581 456 845
110 176 317 488 324 488 3 0 61 -66 127 -147z m3487 -70 c246 -374 407 -651
552 -948 l84 -170 -68 -68 c-54 -56 -415 -396 -524 -495 l-26 -24 -12 59 c-80
409 -207 771 -439 1257 -44 92 -78 173 -74 180 4 6 75 92 158 191 83 99 159
192 170 207 11 15 24 28 28 28 4 0 72 -98 151 -217z m-2601 34 c33 -39 59 -86
92 -172 l47 -117 -17 -44 c-9 -24 -20 -42 -23 -40 -4 2 -50 87 -102 188 l-94
184 19 27 c11 14 23 27 26 27 4 0 27 -24 52 -53z m1516 25 l20 -28 -90 -175
c-50 -95 -96 -180 -102 -186 -10 -10 -16 -4 -29 30 l-16 44 48 121 c35 89 60
135 91 171 24 28 47 50 51 51 4 0 16 -13 27 -28z m-985 -131 c20 -32 36 -62
36 -65 0 -4 -27 7 -60 26 -33 18 -60 36 -60 41 0 18 31 67 40 62 5 -3 25 -32
44 -64z m449 35 c12 -26 13 -37 4 -43 -26 -21 -109 -65 -113 -61 -5 5 79 138
87 138 3 0 13 -15 22 -34z m-3902 -193 c176 -197 390 -425 558 -596 81 -81
148 -154 149 -163 4 -16 -25 -149 -37 -171 -4 -7 -63 38 -158 123 l-151 134
-123 198 c-209 336 -374 612 -365 612 2 0 59 -62 127 -137z m2759 -40 c34 -65
89 -163 121 -219 53 -91 74 -154 51 -154 -5 0 -26 19 -48 43 -21 23 -82 89
-135 147 -53 58 -108 119 -122 136 l-26 31 45 66 c24 37 46 67 48 67 2 0 32
-53 66 -117z m1878 52 c23 -36 42 -68 42 -72 -1 -13 -314 -347 -325 -345 -25
3 -11 41 65 174 44 79 100 180 123 225 23 46 44 83 47 83 3 0 24 -30 48 -65z
m2656 -22 c-59 -103 -282 -465 -368 -600 -84 -130 -101 -150 -211 -247 -65
-58 -122 -106 -125 -106 -3 0 -15 39 -27 87 l-21 87 216 226 c229 238 437 461
512 548 25 28 47 52 48 52 2 0 -9 -21 -24 -47z m-3984 -33 c0 -5 -4 -12 -10
-15 -5 -3 -10 -4 -10 -1 0 2 -3 12 -6 20 -5 12 -2 15 10 10 9 -3 16 -10 16
-14z m686 -5 c-3 -9 -11 -13 -16 -10 -8 5 -7 11 1 21 14 18 24 11 15 -11z
m-327 -242 c11 -38 25 -101 32 -141 11 -74 11 -74 -17 -103 -33 -35 -43 -36
-79 -7 -27 21 -27 24 -22 93 7 79 47 225 62 225 2 0 13 -30 24 -67z m-1094
-33 c52 -58 102 -112 256 -275 l48 -50 -39 -180 c-70 -321 -84 -417 -84 -575
-1 -131 2 -150 23 -197 13 -29 43 -71 68 -94 25 -23 41 -44 36 -48 -26 -23
-183 -127 -273 -181 -123 -74 -207 -113 -223 -103 -19 10 -159 126 -305 251
l-132 113 6 42 c55 402 209 819 472 1275 52 92 67 111 77 100 7 -7 38 -42 70
-78z m2293 -42 c208 -367 341 -691 416 -1008 27 -115 50 -259 44 -283 -3 -14
-171 -162 -346 -305 l-93 -75 -72 34 c-81 39 -283 163 -370 228 l-59 43 31 19
c75 45 112 147 112 304 0 149 -18 264 -108 682 l-17 83 95 97 c52 54 137 144
188 201 52 56 97 102 102 102 4 0 39 -55 77 -122z m-1638 2 c23 -41 69 -112
101 -157 33 -45 59 -87 59 -95 0 -29 49 -115 79 -141 42 -35 88 -45 197 -43
74 2 97 6 127 25 40 24 82 88 98 147 6 20 31 64 56 97 25 34 70 104 101 156
45 78 57 91 63 75 4 -10 8 -28 8 -38 2 -44 -446 -676 -479 -676 -22 0 -480
640 -480 671 0 14 20 62 24 58 3 -2 23 -38 46 -79z m143 -769 c48 -46 86 -89
83 -96 -6 -17 -161 -155 -241 -215 -77 -58 -178 -110 -212 -110 -13 0 -30 11
-39 25 -29 45 -14 268 35 510 23 110 41 202 41 204 0 3 55 -49 123 -115 67
-65 162 -157 210 -203z m910 122 c59 -302 70 -428 44 -503 -9 -27 -18 -36 -39
-38 -62 -7 -235 106 -385 252 l-84 81 207 198 c114 108 210 197 214 197 3 0
23 -84 43 -187z m-3409 38 c-15 -34 -28 -68 -40 -100 -3 -9 -8 -11 -14 -5 -5
5 -1 42 12 92 20 79 21 82 39 66 17 -15 17 -19 3 -53z m5533 17 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-5219 -270 c75 -73 223 -211 327
-307 149 -137 191 -181 193 -203 8 -68 -6 -63 -168 64 -185 145 -521 421 -539
442 -11 13 -9 26 11 76 12 33 26 60 31 60 4 0 69 -60 145 -132z m5087 65 l23
-61 -160 -133 c-177 -147 -513 -415 -539 -429 -14 -8 -18 -3 -21 31 l-4 40
221 204 c121 112 270 251 330 309 61 58 114 104 119 103 4 -2 19 -30 31 -64z
m-2528 -309 c296 -260 686 -549 890 -660 40 -21 73 -41 73 -44 -1 -8 -271
-205 -433 -315 -254 -172 -597 -375 -634 -375 -39 0 -361 190 -623 367 -194
132 -440 312 -440 323 0 4 35 27 77 50 194 107 514 342 810 595 93 79 171 144
175 144 4 1 51 -38 105 -85z m-2634 -111 c105 -86 278 -223 383 -304 119 -92
194 -157 198 -171 3 -13 6 -53 6 -90 0 -98 26 -279 58 -407 15 -61 27 -111 26
-111 -14 0 -497 636 -723 953 -146 204 -162 230 -156 257 4 17 9 30 11 30 2 0
91 -71 197 -157z m5176 9 c-278 -393 -756 -1029 -766 -1019 -1 2 9 57 23 122
13 66 30 191 36 278 l12 157 116 88 c130 98 405 317 555 441 73 61 101 79 103
67 2 -9 -34 -69 -79 -134z m180 -199 c143 -633 89 -1186 -146 -1492 -131 -169
-364 -279 -662 -311 -182 -20 -477 -4 -674 37 l-37 8 111 125 c406 457 890
1069 1264 1598 71 101 111 149 115 140 4 -7 17 -54 29 -105z m-5513 -78 c377
-529 835 -1106 1233 -1555 61 -68 107 -127 103 -131 -4 -4 -61 -15 -127 -25
-183 -29 -532 -26 -675 5 -325 70 -509 205 -629 463 -140 298 -162 756 -61
1242 17 79 30 147 30 151 0 20 29 -14 126 -150z m1109 -170 c43 -36 70 -65 60
-65 -37 0 -105 53 -131 103 -8 15 -12 27 -10 27 3 0 39 -29 81 -65z m3221 26
c-20 -37 -71 -77 -111 -87 -23 -5 -27 -4 -19 5 21 23 139 119 142 116 2 -2 -4
-17 -12 -34z m-3210 -322 c373 -263 666 -453 1023 -660 76 -45 128 -80 120
-83 -602 -208 -956 -134 -1156 241 -74 138 -140 372 -159 557 -6 58 -5 68 7
61 8 -5 82 -57 165 -116z m3308 74 c-11 -93 -44 -260 -68 -339 -94 -313 -245
-496 -471 -567 -102 -32 -326 -31 -475 2 -113 25 -290 78 -304 91 -4 5 11 18
35 30 206 105 821 502 1169 753 58 41 108 76 112 76 4 1 5 -20 2 -46z m-2970
-58 c62 -48 58 -54 -6 -9 -65 45 -64 44 -51 44 6 0 31 -16 57 -35z m2516 31
c0 -2 -28 -23 -62 -46 -69 -46 -68 -41 2 14 41 33 60 43 60 32z m-4354 -551
c39 -228 120 -423 238 -571 278 -352 836 -472 1547 -334 l99 19 252 -253 c271
-271 427 -416 633 -590 72 -60 134 -113 139 -117 9 -8 -159 -135 -324 -246
-406 -270 -777 -440 -1127 -515 -92 -20 -135 -23 -293 -22 -156 0 -197 4 -262
22 -185 51 -331 135 -459 264 -408 412 -575 1248 -482 2418 8 96 10 91 39 -75z
m6157 -495 c2 -376 -5 -502 -43 -753 -105 -690 -370 -1142 -767 -1307 -150
-63 -210 -73 -413 -74 -158 -1 -201 2 -293 22 -350 75 -721 245 -1127 515
-165 111 -333 238 -324 246 5 4 67 57 139 117 208 175 363 320 636 593 l255
257 110 -23 c333 -67 661 -77 919 -28 365 70 616 248 765 544 36 72 90 234
111 331 l14 65 8 -70 c5 -38 9 -234 10 -435z m-2937 -29 c251 -115 653 -267
783 -296 l32 -7 -83 -91 c-173 -189 -869 -827 -903 -827 -33 0 -662 574 -866
790 l-109 114 188 62 c231 76 452 163 642 253 80 38 150 69 156 70 6 1 78 -30
160 -68z m-1393 4 c128 -39 359 -39 541 0 38 8 71 13 73 11 5 -5 -347 -121
-427 -141 -36 -9 -72 -18 -81 -21 -11 -3 -42 24 -94 81 -42 47 -73 85 -68 85
4 0 30 -7 56 -15z m1999 -11 c114 -20 320 -22 411 -5 37 8 70 12 72 10 2 -2
-25 -35 -59 -73 l-63 -70 -69 19 c-86 23 -364 114 -384 126 -21 12 -12 11 92
-7z"/>
        </g>
    </svg>
);

export default FailedHumanitiesSVG;